<template>
  <div class="support">
    <div class="select">
      <select v-model="$i18n.locale" @change="changeLang">
        <option value="en">English</option>
        <!-- <option value="zh">中文</option> -->
        <option value="fr">France</option>
        <option value="de">Deutschland</option>
        <option value="it">Italia</option>
        <option value="pt">Portugal</option>
        <option value="es">español</option>
      </select>
    </div>
    <div class="content flex-column center-a">
      <div style="width: 90%; margin-bottom: 40px">
        <img style="width: 110px" src="/logo2.png" alt="" />
      </div>
      <div class="logo">
        <img style="width: 180px" src="/logo.png" alt="" />
      </div>
      <p class="p1">{{ $t("Register your purchase within") }}</p>
      <p class="p2">{{ $t("15DAYS") }}</p>
      <p class="p3">{{ $t("to get a lifetime warranty.") }}</p>
      <!-- <p>
        {{
          data["title_" + $i18n.locale]
            ? data["title_" + $i18n.locale]
            : data["title_en"]
        }}
      </p> -->
      <a :href="`mailto:${data.email}?subject=${
          data['title_' + $i18n.locale]
            ? data['title_' + $i18n.locale]
            : data['title_en']
        }&body=${
          data['content_' + $i18n.locale]
            ? data['content_' + $i18n.locale]
            : data['content_en']
        }`">
        <div class="click flex-column center">
          <p class="p4">{{ $t("Click Here To") }}</p>
          <img style="width: 50px" src="/point.png" alt="" />
        </div>
      </a>
      <p class="p5">{{ origin }}/form</p>
    </div>
  </div>
</template>

<script>
import { support, getInfo } from "@/api";
export default {
  data: () => ({
    origin: location.origin,
    data: {},
    locale: (navigator.language || "en-US").slice(0, 2)
  }),
  methods: {
    changeLang () { }
  },
  async created () {
    let aa = navigator.language || "en-US";
    aa = aa.slice(0, 2);
    if (aa === "zh") {
      return this.$router.push("/");
    }

    let d = await getInfo();
    console.log(d.msg);
    if (d.msg !== "ok") {
      this.$router.push("/");
    }

    console.log("xxxxxxxxxxxx");
    let res = await support();
    this.data = res.data;
    console.log(res);
  },
  mounted () {
    document.title = "support";
  }
};
</script>
<style lang="scss" scoped>
@font-face {
  font-family: "RS_TeCuHei";
  src: url("/MorganBig-Medium.otf");
}
@font-face {
  font-family: "LTUnivers-Medium";
  src: url("/LT Univers – Medium.otf");
}
@font-face {
  font-family: "Notebook-Bold";
  src: url("/Notebook-Bold.pfb.ttf");
}

.support {
  position: relative;
  background: #556a78;
  min-height: 100vh;
  color: #fff;
  padding: 15px;
  box-sizing: border-box;
  .content {
    max-width: 700px;
    margin: auto;
    .p1 {
      font-size: 18px;
      line-height: 60px;
      margin: 20px 0;
      font-family: LTUnivers-Medium;
    }
    .p2 {
      font-family: monospace;
      font-size: 36px;
      font-weight: bold;
      line-height: 60px;
    }
    .p3 {
      border-bottom: 2px dashed #fff;
      line-height: 100px;
      font-family: LTUnivers-Medium;
    }
    .click {
      height: 160px;
      .p4 {
        // font-family: RS_TeCuHei;
        background: #fff;
        color: #556A78;
        // font-weight: bold;
        border-radius: 30px;
        padding: 10px 10px;
        margin-bottom: 15px;
      }
    }
    .p5 {
      font-family: Notebook-Bold;
      font-weight: bold;
    }
    a {
      text-decoration: none;
    }
  }
  .select {
    position: absolute;
    right: 15px;
    top: 15px;
    z-index: 9999;
    color: #fff;
  }
}
</style>
